import MicroModal from 'micromodal';
import Cookies from 'js-cookie';
import { cleanAutocomplete, fillGeolocInput } from './clean_autocomplete.js';
import eventEmitter from './events.js';
import getAvailabilityMessage from './get_availability_message.js';
import { triggerTracking } from './omniture.js';

const displayWarehouseStatus = (data, codic) => {
    const label = document.querySelector('.status');
    const request = document.querySelector('.request');
    const categoryName = document.querySelector(`[data-btn-codic="${codic}"]`).getAttribute('data-product-category');
    const msg = getAvailabilityMessage(data.msg, data.cities[0].name, data.redirectUrl, categoryName);

    request.classList.remove('js-active');
    label.innerHTML = msg;

    if (data.status === true) {
        label.classList.add('js-valid');
        label.classList.remove('js-invalid');
        const productForm = document.querySelector(`[data-btn-codic="${codic}"]`);
        document.querySelector('.btn.continue').addEventListener('click', () => {
            productForm.submit();
        });
    } else {
        label.classList.add('js-invalid');
        label.classList.remove('js-valid');
    }
};

const checkWarehouse = (codic) => {
    const url = '/nav/extra/ajax/product_warehouse';
    const datas = {
        codic: codic,
        zipCode: Cookies.get('USERZIPCODE') || null,
        insee: Cookies.get('USERINSEECODE') || null,
        cityName: Cookies.get('PCKCITY') || null
    };

    const params = `codic=${datas.codic}&zipCode=${datas.zipCode}&insee=${datas.insee}&cityName=${datas.cityName}`;

    fetch(`${url}?${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((response) => response.json())
        .then((data) => {
            displayWarehouseStatus(data, codic);
        })
        .catch((error) => {
            console.error(error);
        });
};

export default function addDeliverableInit() {
    const $btns = document.querySelectorAll('.button-add-basket:not(.basket-cc)');
    const zipCode = Cookies.get('USERZIPCODE');
    const cityName = Cookies.get('PCKCITY');
    const url = '/nav/extra/warehouses';
    let enableReload = false;

    $btns.forEach((btn) => {
        btn.addEventListener('click', (event) => {
            event.preventDefault();
            const codic = btn.getAttribute('data-btn-codic');
            const isExpediableProduct = btn.getAttribute('data-expediable-product');
            const hasTracking = btn.getAttribute('data-tracking-click') !== null;

            if (hasTracking) {
                const trackingName = btn.dataset.trackingName;
                const trackingEvent = btn.dataset.trackingEvent;
                triggerTracking(trackingEvent, trackingName);
            }

            if ((codic && zipCode && cityName) || isExpediableProduct === 'true') {
                document.querySelector(`[data-btn-codic="${codic}"]`).submit();
            } else {
                MicroModal.show('add-deliverable-popin', {
                    onShow: () => {
                        const $modal = document.getElementById('add-deliverable-popin');
                        const $form = $modal.querySelector('.js-deliverable-form');
                        const $suggestions = $modal.querySelector('.js-clean-suggestions');
                        const $input = $form.querySelector('.js-deliverable-input');
                        const $status = $modal.querySelector('.status');

                        $input.value = fillGeolocInput();
                        $status.innerHTML = '';
                        $status.classList.remove('js-valid', 'js-invalid');

                        cleanAutocomplete($form, $input, $suggestions, url);
                    },
                    onClose: () => {
                        if (enableReload) {
                            window.location.reload();
                        }
                    }
                });
            }

            eventEmitter.on('selectGeolocation', () => {
                checkWarehouse(codic);
                enableReload = true;
            });
        });
    });
}
