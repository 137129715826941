import Cookies from 'js-cookie';
import displayToast from './toast.js';
import { splideInit } from './splide.js';
import eventEmitter from './events.js';

let cookies;
let codics;
let comparatorStatus;
let previousUrl;
let tracking;
let $comparatorButtons = document.querySelectorAll('.button-comparator');
let isLoaded = false;
const $comparator = document.querySelector('.comparator');
const $compareButton = document.querySelector('.compare');

function getCookieValue(name) {
    const cookieArray = document.cookie.split('; ');
    for (let i = 0; i < cookieArray.length; i++) {
        const cookiePair = cookieArray[i].split('=');
        if (name === cookiePair[0]) {
            return cookiePair[1];
        }
    }
    return null;
}

export const getComparatorStatus = () => {
    cookies = Cookies.get('DARTY_COM_COMPARATOR');
    return cookies !== undefined ? cookies.split(':') : [];
};

export const getLiveComparatorStatus = () => {
    return comparatorStatus;
};

export function getComparatorTrackingStatus() {
    return getComparatorStatus().length > 0 ? '1' : '0';
}

const getCodicsLength = () => {
    return getComparatorStatus().length;
};

const updateComparatorStatus = () => {
    codics = getComparatorStatus();
    $comparator.querySelector('.compare').toggleAttribute('disabled', codics.length < 2);
    $comparator.querySelector('.count').textContent = `(${codics.length}/5)`;
};

const updateComparatorVisibility = () => {
    codics = getComparatorStatus();
    $comparator.classList.toggle('js-active', getCookieValue('DARTY_COM_COMPARATOR'));
};

const updateComparator = () => {
    updateComparatorVisibility();
    updateComparatorStatus();
    if ($comparator.classList.contains('js-open')) eventEmitter.emit('fetchProducts');
};

function manageCodicButton(codic) {
    const button = document.querySelector(`.button-comparator[data-codic="${codic}"]`);

    if (getComparatorStatus().indexOf(codic) !== -1) {
        button.classList.add('selected');
    } else {
        button.classList.remove('selected');
    }
}

const addCodic = (codic) => {
    codics = getComparatorStatus();

    codics.unshift(codic);
    if (codics.length > 5) codics.pop();
    Cookies.set('DARTY_COM_COMPARATOR', codics.join(':'), { domain: 'darty.com', path: '/' });
};

const deleteCookiesCodics = () => {
    Cookies.remove('DARTY_COM_COMPARATOR', { domain: 'darty.com', path: '/' });
};

const removeCodic = (codic) => {
    codics = getComparatorStatus();
    const indexToRemove = codics.indexOf(codic);
    codics.splice(indexToRemove, 1);
    Cookies.set('DARTY_COM_COMPARATOR', codics.join(':'), { domain: 'darty.com', path: '/' });

    if (!codics.length) deleteCookiesCodics();
};

const updateCodics = (action, codic) => {
    codics = getComparatorStatus();

    if (!codics) Cookies.set('DARTY_COM_COMPARATOR', { domain: '.darty.com', path: '/' });

    if (action === 'add') {
        addCodic(codic);
    } else {
        removeCodic(codic);
    }
};

async function comparatorRequest(action, codic) {
    try {
        updateCodics(action, codic);
        isLoaded = false;
    } catch (error) {
        console.error('Error:', error);
        displayToast({ message: "Une erreur s'est produite, veuillez réesssayer", type: 'error' });
    } finally {
        updateComparator();
        const message = action === 'add' ? 'Produit ajouté au comparateur' : 'Produit supprimé du comparateur';
        displayToast({ message: message, type: 'success' });
        manageCodicButton(codic);
    }
}

const comparatorReplace = (codic) => {
    const message = 'Vous ne pouvez comparer que 5 produits maximum';
    const confirmation = confirm(message);
    if (confirmation) {
        comparatorRequest('add', codic);
        $comparatorButtons.forEach((button) => {
            manageCodicButton(button.dataset.codic);
        });
    }
};

async function clearCodics() {
    try {
        deleteCookiesCodics();
    } catch (error) {
        console.error('Error:', error);
        displayToast({ message: "Une erreur s'est produite, veuillez réesssayer", type: 'error' });
    } finally {
        updateComparator();
        const message = 'Produits supprimés du comparateur';
        displayToast({ message: message, type: 'success' });
        $comparatorButtons.forEach((button) => {
            manageCodicButton(button.dataset.codic);
        });
    }
}

const compareCodics = () => {
    if (getComparatorStatus().length > 1) {
        previousUrl = encodeURIComponent(document.location.href);
        tracking = '#dartyclic=footer_sticky_comparer';
        window.location.href = `/nav/extra/comparator?previousUrl=${previousUrl}${tracking}`;
    }
};

const manageCodicAction = (codic) => {
    const action = getComparatorStatus().indexOf(codic) === -1 ? 'add' : 'del';
    if (getCodicsLength() === 5 && action === 'add') {
        comparatorReplace(codic);
    } else {
        comparatorRequest(action, codic);
    }
};

const removeProduct = (codic) => {
    comparatorRequest('del', codic);
};

function updateComparatorButtons() {
    $comparatorButtons.forEach((button) => {
        if (getComparatorStatus().includes(button.dataset.codic)) {
            button.classList.add('selected');
        } else {
            button.classList.remove('selected');
        }
    });
}

function toggleProducts() {
    $comparator.classList.toggle('js-open');
    if (!isLoaded) eventEmitter.emit('fetchProducts');
}

async function fetchProducts() {
    const url = '/nav/extra/ajax/refonte_comparator';
    const $comparatorList = document.querySelector('.comparator-list');

    $comparatorList.innerHTML = '<div class="loader"></div>';

    fetch(url)
        .then((response) => response.text())
        .then((data) => {
            $comparatorList.innerHTML = data;
            const $splide = $comparatorList.querySelector('.comparator-slider');
            splideInit($splide);
            isLoaded = true;

            const removeButtons = document.querySelectorAll('.product-close');

            removeButtons.forEach((removeButton) => {
                removeButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    const codic = removeButton.dataset.codic;
                    removeProduct(codic);
                });
            });
        });
}

export default function comparatorInit() {
    $comparatorButtons = document.querySelectorAll('.button-comparator');

    updateComparator();
    updateComparatorButtons();

    $comparatorButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
            event.preventDefault();
            manageCodicAction(button.dataset.codic);
        });
    });

    $comparator.querySelector('.clear').addEventListener('click', clearCodics);
    $compareButton.addEventListener('click', compareCodics);
    $comparator.querySelector('.toggle').addEventListener('click', toggleProducts);
}

eventEmitter.on('fetchProducts', fetchProducts);
