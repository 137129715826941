import Splide from '@splidejs/splide';
import { splideObj } from './multi_sliders.js';

const svgPath =
    'M11.0931174,5.93875 C10.021207,6.93160701 9.95712157,8.60543171 10.9499786,9.67734212 C10.9951961,9.72615993 11.0422393,9.77325437 11.091008,9.81852493 L22.5978408,20.5 L22.5978408,20.5 L11.091008,31.1814751 C10.0201778,32.1754971 9.95791257,33.8493905 10.9519346,34.9202206 C10.9972052,34.9689892 11.0442996,35.0160325 11.0931174,35.06125 C12.2741371,36.1551695 14.0983325,36.1551695 15.2793522,35.06125 L31,20.5 L31,20.5 L15.2793522,5.93875 C14.0983325,4.84483051 12.2741371,4.84483051 11.0931174,5.93875 Z';

export default function splide(
    element,
    itemsPerPage,
    withPadding = false,
    isProducts = false,
    isFlags = false,
    isPagination = false,
    isComparator = false,
    isBundle = false,
    isTopBanner = false
) {
    const splideOptions = {
        pagination: isPagination,
        gap: element.dataset.gap ? parseInt(element.dataset.gap) : 8,
        perMove: element.dataset.perMove ? parseInt(element.dataset.perMove) : '',
        padding: withPadding ? 4 : 0,
        mediaQuery: 'min',
        omitEnd: true,
        lazyLoad: 'sequential',
        arrowPath: svgPath,
        autoWidth: false,
        fixedWidth: element.dataset.fixedWidth ? parseInt(element.dataset.fixedWidth) : 0,
        i18n: {
            prev: 'Slide précédente',
            next: 'Slide suivante',
            carousel: 'Slider',
            slideLabel: 'Slide %s'
        },
        perPage: itemsPerPage.laptop,
        breakpoints: {
            1280: { perPage: itemsPerPage.desktop },
            1600: { perPage: itemsPerPage.desktopXL }
        }
    };

    if (isProducts) {
        splideOptions.autoWidth = true;
    } else if (isComparator) {
        splideOptions.autoWidth = true;
        splideOptions.gap = 16;
    } else if (isFlags) {
        delete splideOptions.fixedWidth;
        delete splideOptions.padding;
        splideOptions.gap = 32;
    } else if (isBundle) {
        splideOptions.autoWidth = true;
    } else if (isTopBanner) {
        delete splideOptions.breakpoints;
    }

    return new Splide(element, splideOptions).mount();
}

function splideParams(splideEl) {
    const itemsPerPage = {
        laptop: splideEl.dataset.itemsPerPageLaptop,
        desktop: splideEl.dataset.itemsPerPageDesktop,
        desktopXL: splideEl.dataset.itemsPerPageDesktopXl
    };

    const withPadding = splideEl.dataset.withPadding === 'true';
    const isProducts = splideEl.dataset.isProducts === 'true';
    const isComparator = splideEl.dataset.isComparator === 'true';
    const isFlags = splideEl.dataset.isFlags === 'true';
    const isPagination = splideEl.dataset.pagination === 'true';
    const isBundle = splideEl.dataset.isBundle === 'true';
    const isTopBanner = splideEl.dataset.isTopBanner === 'true';

    return {
        itemsPerPage,
        withPadding,
        isProducts,
        isFlags,
        isPagination,
        isComparator,
        isBundle,
        isTopBanner
    };
}

export const splideInit = (el) => {
    const splideElements = document.querySelectorAll('.splide');
    let predifinedSplideItem;

    if (el) {
        predifinedSplideItem = el.getAttribute('data-item-id');
        splideObj[predifinedSplideItem] = splide(
            el,
            splideParams(el).itemsPerPage,
            splideParams(el).withPadding,
            splideParams(el).isProducts,
            splideParams(el).isFlags,
            splideParams(el).isPagination,
            splideParams(el).isComparator,
            splideParams(el).isBundle,
            splideParams(el).isTopBanner
        );
    } else {
        for (let i = 0; i < splideElements.length; i++) {
            const splideId = `splide_${i}`;
            splideElements[i].setAttribute('data-item-id', splideId);
            splideObj[splideId] = splide(
                splideElements[i],
                splideParams(splideElements[i]).itemsPerPage,
                splideParams(splideElements[i]).withPadding,
                splideParams(splideElements[i]).isProducts,
                splideParams(splideElements[i]).isFlags,
                splideParams(splideElements[i]).isPagination,
                splideParams(splideElements[i]).isComparator,
                splideParams(splideElements[i]).isBundle,
                splideParams(splideElements[i]).isTopBanner
            );
        }
    }
};
