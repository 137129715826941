window.GLOBAL_FUN = window.GLOBAL_FUN || {};
window.GLOBAL_CONST = window.GLOBAL_CONST || {};

window.GLOBAL_CONST.IS_DCOM_VERSION = $('body').data('is-dcom');
window.GLOBAL_CONST.IS_DCOM_B2B_VERSION = $('body').data('is-b2b');
window.GLOBAL_CONST.IS_MOBILE_VERSION = $('body').data('is-mobile');

/*  ==========================================================================
    Fonctions utiles sur toutes les plateformes et versions (desktop/mobile dcom/dpro)
     * vérifie si le user est connecté
     *
     * on ne checke pas uniquement le PCKFIRSTNAME mais tous les cookies
     * car il le DARTYIDS a une durée de vie moindre que les autres cookies
     * et on en a obligatoirement besoin pour appeler des services websphère
     * et éviter des désynchros (connecté dans catalogue et déconnecté dans websphère)
    ==========================================================================  */
window.GLOBAL_FUN.userIsConnected = function () {
    const loginCookie = window.GLOBAL_FUN.getCookie('PCKLOGIN');
    const dartyIdsCookie = window.GLOBAL_FUN.getCookie('DARTYIDS');
    const firstnameCookie = window.GLOBAL_FUN.getCookie('PCKFIRSTNAME');
    const emailCookie = window.GLOBAL_FUN.getCookie('PCKEMAIL');

    return (
        loginCookie != null &&
        (loginCookie === '1' || loginCookie === '2') &&
        dartyIdsCookie != null &&
        dartyIdsCookie !== '' &&
        firstnameCookie != null &&
        firstnameCookie !== '' &&
        emailCookie != null &&
        emailCookie !== ''
    );
};

/*  ==========================================================================
    Récupération des paramètres d'une URL
    ==========================================================================  */
window.GLOBAL_FUN.getParameterByName = function (name, url) {
    const thisUrl = !url ? window.location.href : url;
    const thisName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + thisName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(thisUrl);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/*  ==========================================================================
    Charge un script dynamiquement
    ==========================================================================  */
window.GLOBAL_FUN.isScriptAlreadyIncluded = function (src) {
    const scripts = document.getElementsByTagName('script');
    let i;
    for (i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') === src) return true;
    }
    return false;
};

window.GLOBAL_FUN.dynamicallyLoadScript = function (url, callback, data) {
    let script;
    let elem;
    if (!window.GLOBAL_FUN.isScriptAlreadyIncluded(url)) {
        script = document.createElement('script');
        if (url && url.length) script.src = url;
        if (data) {
            for (elem in data) {
                if (Object.prototype.hasOwnProperty.call(data, elem)) {
                    script.setAttribute(elem, data[elem]);
                }
            }
        }
        if (callback) {
            script.onreadystatechange = callback;
            script.onload = callback;
        }

        document.head.appendChild(script);
    }
};

/*  ==========================================================================
    Gestion des cookies
    ==========================================================================  */
window.GLOBAL_FUN.setCookie = function (name, value, days) {
    let expires = '';
    let domain;
    const date = new Date();

    // sur le catalogue magasin, le domaine url est différent
    if (window.GLOBAL_CONST.IS_DCOM_VERSION === false && window.GLOBAL_CONST.IS_DCOM_B2B_VERSION === false) {
        domain = '; domain=/' + location.hostname;
    } else {
        domain = '; domain=darty.com';
    }

    if (days) {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
    }

    document.cookie = name + '=' + encodeURIComponent(value) + expires + domain + '; path=/';
};

window.GLOBAL_FUN.getCookie = function (name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    let c;

    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
};

window.GLOBAL_FUN.deleteCookie = function (name) {
    window.GLOBAL_FUN.setCookie(name, '', -1);
};

window.GLOBAL_FUN.saveZipCodeInseeCodeAndCityName = function (city) {
    const days = 365;

    if (city && city.zipCode && city.insee && city.name) {
        window.GLOBAL_FUN.setCookie('USERZIPCODE', city.zipCode, days);
        window.GLOBAL_FUN.setCookie('USERINSEECODE', city.insee, days);
        window.GLOBAL_FUN.setCookie('PCKCITY', city.name, days);
    }
};

window.GLOBAL_FUN.getGeoLoc = function (xPos, yPos, success) {
    return $.ajax({
        type: 'GET',
        dataType: 'json',
        url: '/nav/extra/ajax/gps_insee',
        cache: true,
        data: {
            longitude: yPos,
            latitude: xPos
        },
        success: success
    });
};

/*  ==========================================================================
    Detect qu'un élément est visible et déclenche une fonction callback
    Utile pour lazyloader des blocs partenaires
    ==========================================================================  */
window.GLOBAL_FUN.detectIsOnScreen = function ($element, marginBeforeElementParam, callback) {
    const element = $element[0];
    const marginBeforeElement = marginBeforeElementParam || 0;
    const $document = $(document);

    $document.on(
        'scroll',
        $.throttle(500, function () {
            if ($element.data('visible')) return;
            if (window.GLOBAL_FUN.checkElementIsVisible(element, marginBeforeElement)) {
                $element.data('visible', true);
                callback();
            }
        })
    );
};

window.GLOBAL_FUN.checkElementIsVisible = function (element, marginBeforeElement) {
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0 + marginBeforeElement);
};

$('[data-js="new-order-reset"]').on('submit', function () {
    GLOBAL_FUN.deleteCookie('session_user');
});
