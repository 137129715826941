import MicroModal from 'micromodal';
import { userInseeCode, userZipCode, userCity, isUserLocationValid } from './cookies.js';
import SaveGeoloc from './save_geoloc.js';
import GeolocAutocomplete from './geoloc_autocomplete.js';

const userInseeCodeFromCookie = userInseeCode();
const userZipCodeFromCookie = userZipCode();
const userCityFromCookie = userCity();
const $popinBtn = document.querySelector('.geoloc');
const $popinBtnLabel = $popinBtn.querySelector('.label');
const $form = document.querySelector('.js-geoloc-form');
const $input = $form.querySelector('.js-geoloc-input');
const $suggestions = document.querySelector('.js-suggestion-zipcode');
const $invalidErrorMessage = document.querySelector('.js-error-invalid');
const $reachErrorMessage = document.querySelector('.js-error-reach');
const $enableErrorMessage = document.querySelector('.js-error-enable');
const serviceUrl = '/nav/extra/express_delivery';
const $geolocBtn = $form.querySelector('.js-geoloc-button');
const messages = [$invalidErrorMessage, $reachErrorMessage, $enableErrorMessage];

$form.addEventListener('hideAllErrorMessages', () => {
    messages.forEach((message) => {
        message.classList.remove('js-show');
    });
});

const fillLabel = () => {
    $popinBtnLabel.innerHTML = `${userCityFromCookie} (${userZipCodeFromCookie})`;
    $popinBtnLabel.classList.add('js-label-active');
};

const processNewLocation = (dataPosition) => {
    if (dataPosition.isReachable === false) {
        $form.dispatchEvent(new Event('hideAllErrorMessages'));
        return;
    }

    if (dataPosition.insee !== userInseeCodeFromCookie) {
        SaveGeoloc(dataPosition);
        window.location.reload();
    }
};

const convertGpsToInsee = (latitude, longitude) => {
    return new Promise((resolve) => {
        fetch(`/nav/extra/ajax/gps_insee?latitude=${latitude}&longitude=${longitude}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((data) => {
                resolve({
                    zipCode: data.zip,
                    insee: data.insee,
                    name: data.name
                });
            })
            .catch(() => {
                $form.dispatchEvent(new Event('hideAllErrorMessages'));
                $reachErrorMessage.classList.add('js-show');
            });
    });
};

const successGetUserCoordinates = (position) => {
    const { latitude, longitude } = position.coords;
    convertGpsToInsee(latitude, longitude).then((dataPosition) => {
        processNewLocation(dataPosition);
    });
};

const errorGetUserCoordinates = () => {
    $form.dispatchEvent(new Event('hideAllErrorMessages'));
    $enableErrorMessage.classList.add('js-show');
};

export const getUserCoordinates = () => {
    const options = { timeout: 25000 };
    navigator.geolocation.getCurrentPosition(successGetUserCoordinates, errorGetUserCoordinates, options);
};

$geolocBtn.addEventListener('click', getUserCoordinates);

const updateInputValue = (input, value) => {
    // eslint-disable-next-line no-param-reassign
    input.value = value;
};
const initAutoCompleteGeolocation = () => {
    const fillInput = (zipcode, city) => {
        return `${city
            .replace(/'(.+)'/g, '$1')
            .split('+')
            .join(' ')} (${zipcode})`;
    };

    if (userInseeCodeFromCookie && userZipCodeFromCookie && userCityFromCookie) {
        const formattedValue = fillInput(userZipCodeFromCookie, userCityFromCookie);
        updateInputValue($input, formattedValue);
    }

    $input.addEventListener('focus', () => {
        $input.value = '';
    });
    $input.addEventListener('blur', () => {
        $input.value = '';
    });

    GeolocAutocomplete($input, serviceUrl, $suggestions, $invalidErrorMessage);

    if (!navigator.geolocation) {
        $geolocBtn.classList.add('suggestion_hidden');
    }
};

export default function geolocInit() {
    MicroModal.init({
        onShow: (modal) => {
            if (modal.id === 'geoloc_popin') {
                initAutoCompleteGeolocation();
                $form.dispatchEvent(new Event('hideAllErrorMessages'));
            }
        }
    });

    if (userInseeCodeFromCookie && userZipCodeFromCookie && userCityFromCookie) {
        fillLabel();
    }
}

export function getUserLocationTrackingStatus() {
    if (isUserLocationValid()) {
        return '1';
    }
    return '0';
}

export function getZipcodeUserTracking() {
    return userZipCodeFromCookie !== undefined ? userZipCodeFromCookie : '';
}
